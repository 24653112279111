import React, { useEffect, useState } from "react"; 
import Footer from "../components/Footer.js";  
import Navbar from "../components/Navbar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms() {
 
 

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper bg_none">
        <div className="container">
          <div className="dashboard_panel join_now_panel termspage">
             <h2 className="inner_title mb-4">Terms and Conditions of Webonjour  </h2>  
             <p>The utilization of Webonjour's platform and services is governed by the terms of service. By accessing and utilizing our platform's services, users are deemed to have agreed to both the terms and our privacy policy. Failure to agree to the terms and conditions will result in the inability to use our platform and services.</p>     
             <h5>Acceptance of Terms</h5>
             <p>By using the Website, you affirm that you are at least 18 years old or have the legal capacity to enter into a binding agreement. You acknowledge that you have read, understood, and agree to be bound by these Terms and any additional terms and conditions that may apply to specific sections of the Website.</p>
             <h5>Website Access and Use </h5>
             <p className="mb-4">Subject to these Terms, we grant you a non-exclusive, non-transferable, revocable license to access and use the Website for personal, non-commercial purposes. </p>
             <p className="agree" >You agree not to: </p>
             <ul>
                <li>Use the Website for any unlawful or unauthorized purpose. </li>
                <li>Attempt to gain unauthorized access to any portion of the Website or any other systems or networks connected to the Website. </li>
                <li>Interfere with or disrupt the operation of the Website or any servers or networks connected to the Website. </li>
                <li>Engage in any form of automated data collection, such as scraping, crawling, or data mining. e. Modify, adapt, translate, or create derivative works based on the Website or any portion thereof. </li>
                <li>Remove, alter, or obscure any copyright, trademark, or other proprietary rights notices from the Website or any content therein.</li>
             </ul>
             <h5>User Content</h5>
             <ul>
                <li>The Website may allow you to submit or post content, including but not limited to text, images, videos, or comments ("User Content"). </li>
                <li>By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, publicly display and perform, and create derivative works of the User Content in any media now known or hereafter developed. </li>
                <li>You represent and warrant that you have the necessary rights to grant the above license for any User Content you submit, and that the User Content does not infringe upon the rights of any third party. </li>
                <li>We reserve the right to remove or modify any User Content that violates these Terms or for any other reason at our sole discretion.</li>
             </ul>


            <h5>  Account Creation and User Responsibility</h5>
           <p>In order to access our platform and services, you are required to create an account and provide accurate personal information. It is your sole responsibility to maintain the confidentiality of your account and password. You agree to promptly notify us of any unauthorized use of your account and password.</p>
            <h5>Intellectual Property Rights </h5>
             <p>The content, data, and infographics (including text, graphics, logos, images, and software) available on our platform and services are the exclusive property of Bonjour and are protected by copyright and other intellectual property laws. Any use, reproduction, modification, display, or distribution of such content without our prior written consent is strictly prohibited.</p>
            <h5>Privacy</h5>
            <p>Your use of the Website is subject to our Privacy Policy, which explains how we collect, use, and disclose your personal information. By using the Website, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.</p>
            
            <h5> Disclaimer of Warranties</h5>
             <ul>
                <li>The Website and its content are provided on an "as is" and "as available" basis without warranties of any kind, whether express or implied.</li>
                <li>We do not warrant that the Website will be uninterrupted, error-free, or free from viruses or other harmful components. </li>
                <li>We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
             </ul>
              
              <h5>Limitation of Liability </h5>
              <p>To the maximum extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the Website.</p>

              <h5>Indemnification </h5>
              <p>You agree to indemnify, defend, and hold us harmless from any claims, liabilities, damages, losses, costs, or expenses, including attorneys' fees, arising out of or in connection with your use of the Website or violation of these Terms.</p>

              <h5>Modifications to the Terms</h5>
              <p>We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after the posting of any changes constitutes your acceptance of the modified Terms.</p>

              <h5>Approvement</h5>
              <p>BY MAKING USE OF WEBONJOUR COMMUNITY SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: </p>
              <ul>
                <li>YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES.</li>
                <li>YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF WEBONJOUR COMMUNITY SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES.</li>
                <li>WEBONJOUR SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</li>
              </ul>

              <h5>Termination of service</h5>
              <p>In the event that users violate these terms, their account and access to our platform and services may be terminated. We reserve the right to discontinue your use of our platform and services at any time, with or without prior notice, if we detect any fraudulent or deceptive activities.</p>

              <h5>Governing Law and Jurisdiction </h5>
              <ul>
                <li>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of laws principles. </li>
                <li>Any dispute arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the courts.</li>
              </ul>
              
              <h5>Severability </h5>
              <p>If any provision of these Terms is held to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.</p>
 
              <h5>Entire Agreement</h5>

             <p>These Terms constitute the entire agreement between you and Webonjour Ltd. With respect to your use of the Website and supersede all prior or contemporaneous communications and proposals, whether oral or written.</p>

             <p>By accessing, using, or attempting to use Webonjour Community Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access webonjour Community services.</p>

              <p>Please read these Terms carefully before using the Webonjour Website. If you have any questions or concerns about these Terms, please contact us at [contact information].</p>




          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
