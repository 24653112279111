import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import { useParams } from "react-router-dom";
import Navbar from "../components/autopool2nave.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import moment from "moment";
import syncEach from "sync-each";
import { getCurAddr, updateregister, checkreferal } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import autopoolabi from "../ABI/autopool2";
import busdabi from "../ABI/busdABI";
import config from '../config/config';
import { type WalletClient, useWalletClient } from 'wagmi'

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DashboardNew(props) {
  let data = {
    name: "ID 56001",
    children: [
      {
        name: "ID 56002(5)",
      },
      {
        name: "ID 56003(12)",
      },
      {
        name: "ID 56004(8)",
      },
    ],
  };

  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    window.addEventListener("resize", larg);
    getdetails();
  }, [walletClient]);


  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  var { id } = useParams();

  const [selected, setSelected] = useState(data);
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const [referaladdress, setreferaladdress] = useState(id);
  const [hadalready, sethadalready] = useState("no");
  const [progress, setprogress] = useState("false");
  const [investamt, setinvestamt] = useState(50);
  const [userexist, setuserexist] = useState("no");
  const [usersdetail, setusers] = useState({});
  const [earnedamt, setearnedamt] = useState(0);
  const [refferallist, setrefferallist] = useState([]);
  const [totalusers, settotalusers] = useState(0);
  const [treeuser, settreeuser] = useState({});
  const [uplineamt, setuplineamt] = useState(0);
  const [searchid, setsearchid] = useState("");
  const [myidlist, setmyidlist] = useState([]);

  const referralchange = (e) => {
    setreferaladdress(e.target.value);
  }

  const investamtchange = (e) => {
    setinvestamt(e.target.value);
  }

  const idchange = (e) => {
    setsearchid(e.target.value)
  }

  async function searchbyid() {
    let datas = await getCurAddr();
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
      var autoContract = new web3.eth.Contract(autopoolabi, config.autopoolcontract2);
      let usersdet = await autoContract.methods.users(searchid).call();
      settreeuser(usersdet);
    }
  }

  async function empty() {

  }

  async function checkreferal() {
    if (id && id != null && id != undefined && id != "") {
      setreferaladdress(id);
    }
  }

  async function getdetails() {
    let datas = await getCurAddr();
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
      var autoContract = new web3.eth.Contract(autopoolabi, config.autopoolcontract2);
      let userids = await autoContract.methods.userList(datas.address).call();
      let usersdet = await autoContract.methods.users(userids).call();
      if (usersdet && usersdet.isExist) {
        setuserexist("yes")
        setusers(usersdet);
        settreeuser(usersdet);
        let upline_amt = await autoContract.methods.uplineAmount().call();
        setuplineamt(upline_amt);
        let amt = await autoContract.methods.totalearnedAmount(datas.address).call();
        setearnedamt(amt);
        let refferal = await autoContract.methods.viewallDirectAddress(datas.address).call();
        setrefferallist(refferal);
        let total = await autoContract.methods.totalUsers().call();
        settotalusers(total);
        let myids = await autoContract.methods.viewallUserIdByAddress(datas.address).call();
        let arrs = [];
        syncEach(myids, async function (items, next) {
          let myuserprof = await autoContract.methods.users(items).call();
          arrs.push(myuserprof);
          next();
        }, function (err, transformedItems) {
          arrs.reverse();
          setmyidlist(arrs)
        })
      } else {
        setuserexist("no")
      }
    }
  }

  async function joinnow() {
    try {
      setprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" && localStorage.getItem("account")) {
        let userrefferal = referaladdress && referaladdress != null && referaladdress != undefined && referaladdress != "" ? referaladdress : config.autopool2_rootuser;
        if (userrefferal.toLowerCase() != (datas.address).toLowerCase()) {
          let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
          if (web3.utils.isAddress(userrefferal)) {
            var autopoolContract = new web3.eth.Contract(autopoolabi, config.autopoolcontract2);
            let investamt = await autopoolContract.methods.JoinAmount().call();
            if (investamt && parseFloat(investamt) > 0) {
              var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
              let userids = await autopoolContract.methods.userList(userrefferal).call();
              let userdetails = await autopoolContract.methods.users(userids).call();
              if (userdetails.isExist) {
                let sendamt = await convert(investamt);
                let allowedamt = await convert(10000000000000000000000000)
                if (parseFloat(datas.busdvalue) >= parseFloat(sendamt)) {
                  var allowance = await busdContract.methods.approve(config.autopoolcontract2, allowedamt.toString()).send({
                    from: datas.address
                  });
                  if (allowance) {
                    let register = await autopoolContract.methods.regUser(userrefferal, sendamt.toString()).send({
                      from: datas.address
                    })
                    if (register && register.status && register.transactionHash) {
                      // let loguser = await autopoolContract.methods.users(datas.address).call();
                      // let reg_data = {
                      //   address : datas.address,
                      //   referraladdress : referaladdress,
                      //   userid : loguser.id,
                      //   amount : investamt,
                      //   event  : "joined",
                      //   hash : register.transactionHash
                      // }
                      // await updateregister(reg_data);
                      setprogress("false")
                      toastAlert('success', "Joined Successfully", 'network');
                      getdetails();
                    } else {
                      setprogress("false")
                      toastAlert('success', "Failed , try again later", 'network');
                    }
                  } else {
                    setprogress("false")
                    toastAlert('success', "Failed , try again later", 'network');
                  }
                } else {
                  setprogress("false")
                  toastAlert('error', "Low USDT Balance", 'network');
                }
              } else {
                setprogress("false")
                toastAlert('error', "Referral user not exist", 'network');
              }
            } else {
              setprogress("false")
              toastAlert('error', "Enter Valid Invest Amount", 'network');
            }
          } else {
            if (userrefferal && userrefferal != null && userrefferal != undefined && userrefferal != "") {
              setprogress("false")
              toastAlert('error', "Invalid Referral Link", 'network');
            } else {
              setprogress("false")
              toastAlert('error', "Please Enter valid Address", 'network');
            }
          }
        } else {
          setprogress("false")
          toastAlert('error', "Your Wallet and Referral both are same", 'network');
        }
      } else {
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      setprogress("false")
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText =
      config.Front_URL + "bonjourpool2/" + localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      {userexist && userexist == "yes" ?
        <div className="inner_wrapper">
          <div className="container">
            <div className="dashboard_panel dashboard_panel_new">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_flex">
                    <h2 className="inner_title">Dashboard</h2>
                    <div className="wallet_div">
                      <label>Affiliate link:</label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          defaultValue={config.Front_URL + "bonjourpool2/" + localStorage.getItem("account")}
                        />
                        <button className="copy_btn" onClick={copyToClipboard}>
                          <img
                            src={require("../assets/images/affiliate_icon.png")}
                            className="img-fluid"
                            alt="Affiliate"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboard_top_wrap">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="total_income_div">
                      <h2>
                        Total Income: <span>{(parseFloat(earnedamt) / 1e18).toFixed(2)} USDT</span>
                      </h2>
                    </div>
                    <div className="primary_box primary_box_new primary_box_new_mt">
                      <div className="primary_box_new_div">
                        <img
                          src={require("../assets/images/new_dash_icon_01.png")}
                          className="img-fluid"
                          alt="My Present ID"
                        />
                        <div>
                          <h2>{usersdetail && usersdetail.id ? usersdetail.id : "--"}</h2>
                          <h3>My Present ID</h3>
                        </div>
                      </div>
                      <div className="primary_box_new_div">
                        <img
                          src={require("../assets/images/new_dash_icon_02.png")}
                          className="img-fluid"
                          alt="My Present ID"
                        />
                        <div>
                          <h2>{totalusers}</h2>
                          <h3>All participants</h3>
                        </div>
                      </div>
                      <div className="primary_box_new_div">
                        <img
                          src={require("../assets/images/new_dash_icon_03.png")}
                          className="img-fluid"
                          alt="My Present ID"
                        />
                        <div>
                          <h2>{refferallist && refferallist.length > 0 ? parseInt(refferallist.length) * 5 : 0}</h2>
                          <h3>Direct Referral Amount</h3>
                        </div>
                      </div>
                      <div className="primary_box_new_div">
                        <img
                          src={require("../assets/images/new_dash_icon_04.png")}
                          className="img-fluid"
                          alt="My Present ID"
                        />
                        <div>
                          <h2>{refferallist && refferallist.length > 0 ? refferallist.length : 0}</h2>
                          <h3>Direct Referral</h3>
                        </div>
                      </div>
                    </div>

                    <div className="inp_grp search_div">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        placeholder="Search by id"
                        onChange={idchange}
                        value={searchid}
                      />
                      <button onClick={() => searchbyid()}>
                        <i className="bi bi-search"></i>
                      </button>
                    </div>

                    <div className="tree_structure">
                      <p className="text-center mb-0">{"ID" + treeuser.id}</p>
                      <div className="tree_first_level">
                        <img
                          src={require("../assets/images/tree_user_id.png")}
                          className="img-fluid"
                          alt="My Present ID"
                        />
                      </div>
                      {treeuser && treeuser.childId && treeuser.childId != 0 &&
                        <div className="tree_second_level">
                          <div>
                            <img
                              src={require("../assets/images/tree_user_id.png")}
                              className="img-fluid"
                              alt="My Present ID"
                            />
                          </div>
                        </div>
                      }
                      {treeuser && treeuser.childId && treeuser.childId != 0 &&
                        <p className="text-center mb-0">{"ID" + treeuser.childId}</p>
                      }

                    </div>
                  </div>
                  <div className="col-lg-8 m-auto">
                    <h2 className="inner_title">Rebirth History</h2>
                    <div class="rebirth_table_div">
                      <div class="table-responsive">
                        <table class="table rebirth_table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Date</th>
                              <th>My ID</th>
                              <th>Contract Address</th>
                            </tr>
                          </thead>
                          <tbody>
                            {myidlist &&
                              myidlist.length > 0 &&
                              myidlist.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{parseInt(i) + 1}</td>
                                    <td>{moment.unix(item.userJoined).format("DD/MM/YYYY HH:mm")}</td>
                                    <td>{item && item.id ? "ID" + item.id : "--"}</td>
                                    <td><span className="dash_new_address">{config.autopoolcontract2}</span></td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="inner_wrapper bg_none">
          <div className="container">
            <div className="dashboard_panel join_now_panel">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <div className="title_flex">
                    <h2 className="inner_title">Join Now</h2>
                    <div className="primary_box dash_box mt-4">
                      <div className="form-group row">
                        <div className="col-lg-6 m-auto text-start">
                          <label for="exampleFormControlInput1" class="form-label">Referral ID</label>
                          <input type="text" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter referral ID (Optional)" onChange={referralchange} value={referaladdress} />
                          <label for="exampleFormControlInput1" class="form-label">Invest Amount (USDT)</label>
                          <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Invest Amount" value={investamt} onChange={investamtchange} disabled="disabled" />
                          <button className="primary_btn" type="button" onClick={progress == "false" ? () => joinnow() : () => empty()}>{progress == "false" ? "Submit" : "Processing..Please Wait.."}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Footer />
    </div>
  );
}
