import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar_inner from "../components/Navbar_inner.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import {
  getCurAddr,
  save_cartrequest
} from "../action/Apicontroller.js";
import { toastAlert } from "../helper/toastAlert.js";
import bonjourapi from "../ABI/bonjourABI";
import busdabi from "../ABI/busdABI";
import config from "../config/config.js";
import QRCode from "react-qr-code";
import { type WalletClient, useWalletClient } from 'wagmi'

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function NFCCard(props) {
  //var { id } = useParams();
  const [data, setdata] = useState({});
  const [progress, setprogress] = useState("false");
  const [investamt, setinvestamt] = useState(0);
  const [hadalready, sethadalready] = useState("no");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [wallet, setwallet] = useState("");


  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })


  useEffect(() => {
  }, [walletClient]);

  const namechange = (e) => {
    setname(e.target.value);
  }

  const emailchange = (e) => {
    setemail(e.target.value);
  }

  const numberchange = (e) => {
    setmobile(e.target.value);
  }

  const addresschange = (e) => {
    setaddress(e.target.value);
  }

  const walletchange = (e) => {
    setwallet(e.target.value);
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  async function submitdetails() {
    setprogress("true");
    if(name && name!=null && name!=undefined && name!=""){
      if (isValidEmail(email)) {
        if(mobile && mobile!=null && mobile!=undefined && mobile!=""){
          if(address && address!=null && address!=undefined && address!=""){
            if(wallet && wallet!=null && wallet!=undefined && wallet!=""){
              let datas = await getCurAddr();
              let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
              if(web3.utils.isAddress(wallet)){
                let record = {
                  "wallet" : wallet,
                  "address" : address,
                  "mobile" : mobile,
                  "email" : email,
                  "name" : name
                }
                await save_cartrequest(record);
                toastAlert("success", "Submitted Successfully", "success");
                setprogress("false")
                window.location.reload();
              }else{
                setprogress("false")
                toastAlert("error", "Enter Valid Wallet Address", "error");
              }
            }else{
              setprogress("false")
              toastAlert("error", "Enter Valid Wallet Address", "error");
            }
          }else{
            setprogress("false")
            toastAlert("error", "Enter Valid Address", "error");
          }
        }else{
          setprogress("false")
          toastAlert("error", "Enter Valid Mobile", "error");
        }
      }else{
        setprogress("false")
        toastAlert("error", "Enter Valid Email", "error");
      }
    }else{
      setprogress("false")
      toastAlert("error", "Enter Valid Name", "error");
    }
  }

  async function empty(){

  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.nftcartadminaddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar_inner />
      <div className="inner_wrapper bg_none">
        <div className="container">
          <div className="dashboard_panel join_now_panel nfc_card_panel">
            <div className="row">
              <div className="col-lg-6">
                <div className="title_flex">
                  <h2 className="inner_title text-start mb-3">NFC Card</h2>
                  <p className="text-start">
                    An NFC (Near Field Communication) card is a type of smart card that uses wireless communication technology to enable secure data transfer between devices. To use an NFC card, the user typically needs to tap or wave the card near an NFC-enabled payment terminal device. The NFC technology allows for quick and convenient communication between the card and the device, allowing the user to complete their transaction.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="nfc_card_wrap">
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="trigger"></div>
                  <div className="nfc_card_img"></div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-10 col-xl-8 m-auto text-center">
                <div className="primary_box dash_box mt-4 nfc_card_buy_box">
                  <h2 className="inner_title mb-3">How To Buy NFC Card?</h2>
                  <p className="mb-2 nfc_card_price">Buy NFC Card for 250$</p>
                  <p className="custom_width">
                    To get our NFC Card complete the payment using the below QR Code or wallet address and fill the user details form.
                  </p>
                  <div className="img-fluid nfc_qr">
                    <QRCode
                      value={config.nftcartadminaddress}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  {/*<img
                    src={require("../assets/images/qr.jpg")}
                    alt="QR"
                    className="img-fluid nfc_qr"
                  />*/}
                  <div className="wallet_div nfc_admin_address">
                    <label>Admin Address</label>
                    <div className="inp_grp">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        defaultValue={config.nftcartadminaddress}
                        disabled="disabled"
                      />
                      <button className="copy_btn" onClick={copyToClipboard}>
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="copy_icon wallet_icon"
                          alt="Copy"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="nfc_form">
                    <h3>User Details</h3>
                    <div className="row">
                      <div className="col-lg-8 col-xl-6 m-auto text-start">
                        <div className="form-group">
                          <label
                            for="username"
                            class="form-label"
                          >
                            User Name
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="username"
                            placeholder="Enter User Name"
                            onChange={namechange}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            for="email"
                            class="form-label"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            class="form-control primary_inp"
                            id="email"
                            placeholder="Enter Email"
                            onChange={emailchange}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            for="phonenumber"
                            class="form-label"
                          >
                            Phone Number
                          </label>
                          <input
                            type="number"
                            class="form-control primary_inp"
                            id="phonenumber"
                            placeholder="Enter Phone Number"
                            onChange={numberchange}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            for="address"
                            class="form-label"
                          >
                            Address
                          </label>
                          <textarea placeholder="Enter Address" rows={4} class="form-control primary_inp" id="address" onChange={addresschange}/>                          
                        </div>

                        <div className="form-group">
                          <label
                            for="walletaddress"
                            class="form-label"
                          >
                            Wallet Address
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="walletaddress"
                            placeholder="Enter Wallet Address"
                            onChange={walletchange}
                          />
                        </div>

                        <div className="text-center">
                          <button className="primary_btn" type="button" onClick={progress == "false" ? ()=>submitdetails(): ()=>empty()}>
                            {progress == "false"
                              ? "Submit"
                              : "Processing..Please Wait.."}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
