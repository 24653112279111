import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar_inner from "../components/Navbar_inner.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import busdabi from "../ABI/busdABI";
import config from '../config/config';
import Navbar from "../components/Navbar.js";
import planabi from "../ABI/PlanABI";
import { type WalletClient, useWalletClient } from 'wagmi'
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function JoinNowplan(props) {
  var { id } = useParams();
  const [data, setdata] = useState({});
  const [referaladdress, setreferaladdress] = useState(id);
  const [progress, setprogress] = useState("false");
  const [investamt, setinvestamt] = useState(1);
  const [hadalready, sethadalready] = useState("no");
  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    getdetails();
  }, [walletClient]);

  const referralchange = (e) => {
    setreferaladdress(e.target.value);
  }

  const investamtchange = (e) => {
    setinvestamt(e.target.value);
  }


  async function getdetails() {
    let datas = await getCurAddr();
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      setdata(datas);
      let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
      var planContract = new web3.eth.Contract(planabi, config.plancontract);
      let user_details = await planContract.methods.users(datas.address).call();
      if (user_details &&
        user_details.isExist) {
        window.location.href = "/MiniBonjour"
      } else {

      }
    }
  }



  async function empty() {

  }

  async function joinnow() {
    try {
      setprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" && localStorage.getItem("account")) {
        let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
        var planContract = new web3.eth.Contract(planabi, config.plancontract);
        let user_details = await planContract.methods.users(datas.address).call();
        if (user_details &&
          user_details.isExist) {
          setprogress("false")
          toastAlert('error', "Already Joined", 'network');
          window.location.href = "/MiniBonjour";
        } else {
          if (referaladdress.toLowerCase() != (datas.address).toLowerCase()) {
            let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
            if (web3.utils.isAddress(referaladdress)) {
              if (investamt && parseFloat(investamt) > 0) {
                var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
                var planContract = new web3.eth.Contract(planabi, config.plancontract);
                let userdetails = await planContract.methods.users(referaladdress).call();
                if (userdetails.isExist) {
                  let invest_amt = (parseFloat(investamt) * 1e18)
                  let sendamt = await convert(invest_amt);
                  let allowedamt = await convert(10000000000000000000000000)
                  if (parseFloat(datas.busdvalue) >= parseFloat(sendamt)) {
                    var allowance = await busdContract.methods.approve(config.plancontract, allowedamt.toString()).send({
                      from: datas.address
                    });
                    if (allowance) {
                      let register = await planContract.methods.regUser(referaladdress, sendamt.toString()).send({
                        from: datas.address
                      })
                      if (register && register.status && register.transactionHash) {
                        setprogress("false")
                        toastAlert('success', "Joined Successfully", 'network');
                        window.location.href = "/MiniBonjour"
                      } else {
                        setprogress("false")
                        toastAlert('success', "Failed , try again later", 'network');
                      }
                    } else {
                      setprogress("false")
                      toastAlert('success', "Failed , try again later", 'network');
                    }
                  } else {
                    setprogress("false")
                    toastAlert('error', "Low USDT Balance", 'network');
                  }
                } else {
                  setprogress("false")
                  toastAlert('error', "Referral user not exist", 'network');
                }
              } else {
                setprogress("false")
                toastAlert('error', "Enter Valid Invest Amount", 'network');
              }
            } else {
              if (referaladdress && referaladdress != null && referaladdress != undefined && referaladdress != "") {
                setprogress("false")
                toastAlert('error', "Invalid Referral Link", 'network');
              } else {
                setprogress("false")
                toastAlert('error', "Please Enter valid Address", 'network');
              }
            }
          } else {
            setprogress("false")
            toastAlert('error', "Your Wallet and Referral both are same", 'network');
          }
        }
      } else {
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      console.log(err, "=====errr")
      setprogress("false")
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper bg_none joinnowplan">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2>Join Now for 1$ working plan</h2>
                  <div className="primary_box dash_box mt-4">
                    <div className="form-group row">
                      <div className="col-lg-6 m-auto text-start">
                        <label for="exampleFormControlInput1" class="form-label">Referral Address</label>
                        <input type="text" class="form-control primary_inp" id="exampleFormControlInput1"
                          placeholder="Enter referral Address" onChange={referralchange} disabled={hadalready && hadalready != undefined && hadalready != null && hadalready != "no" ? "disabled" : ""} value={referaladdress} />
                        <label for="exampleFormControlInput1" class="form-label mt-3">Invest Amount (USDT)</label>
                        <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Invest Amount" value={investamt} onChange={investamtchange} min={data.minvalue} max={data.maxvalue} disabled />
                        <button className="primary_btn" type="button" onClick={progress == "false" ? () => joinnow() : () => empty()}>{progress == "false" ? "Submit" : "Processing..Please Wait.."}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
