import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import bonjourabi from "../ABI/bonjourABI";
import busdabi from "../ABI/busdABI";
import bonjourtokenabi from "../ABI/bonjourtokenABI";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from 'ethereum-multicall';
import BigNumber from "bignumber.js";

import { toastAlert } from "../helper/toastAlert";

import store from "../store";


export const getCurAddr = async () => {
  try{
    var reduxVal = store.getState();
    var transfort = (reduxVal && reduxVal.walletConnection&& reduxVal.walletConnection && reduxVal.walletConnection.web3 && reduxVal.walletConnection.web3.key)?reduxVal.walletConnection.web3:{}
    console.log(transfort,"===========")
    if(transfort && transfort!=null && transfort!=undefined && transfort!=""){
      console.log("inside")
      var web3  =new Web3(transfort) 
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      let bnbbalance = await web3.eth.getBalance(result[0]);
      console.log(bnbbalance,"=====network",network)
      if(network == config.networkVersion){
        localStorage.setItem("account",result[0]);
        var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
        let busdbalance = await busdContract.methods.balanceOf(result[0]).call();
        var bonjourContract = new web3.eth.Contract(bonjourtokenabi, config.bonjourtokencontract);
        let bonjourbalance = await bonjourContract.methods.balanceOf(result[0]).call();
        localStorage.setItem("account",result[0]);
        const multicall = new Multicall({
            web3Instance: web3,
        });
        var contractCallContext = [
          {
              reference: "details",
              contractAddress: config.bonjourcontract,
              abi: bonjourabi,
              calls: [
                  {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [localStorage.getItem("account")],
                  },
                  {
                      reference: "minInvestment",
                      methodName: "minInvestment",
                      methodParameters: [],
                  },
                  {
                      reference: "maxInvestment",
                      methodName: "maxInvestment",
                      methodParameters: [],
                  },
                  {
                      reference: "minWithdrawAmount",
                      methodName: "minWithdrawAmount",
                      methodParameters: [],
                  },
              ],
          },
        ];
        const results = await multicall.call(contractCallContext);
        var userdetails = await getFormatMulticall(results, "userprofile", 0);
         let userexist = true;
        let userid = await bignumber(userdetails[1]);
        let levelIncomeEligible = await bignumber(userdetails[2]);
        let referralid = await bignumber(userdetails[3]);
        let investmentAmount = await bignumber(userdetails[4]);
        let earnedAmount = await bignumber(userdetails[5]);
        let levelIncome = await bignumber(userdetails[6]);
        let globalPoolIncome = await bignumber(userdetails[7]);
        let referralCount = await bignumber(userdetails[8]);
        let referralIncome = await bignumber(userdetails[9]);
        let teamVolume = await bignumber(userdetails[10]);
        let data_user = {
          "isExist" : userdetails[0],
          "userid"    : userid,
          "levelIncomeEligible" : parseFloat(levelIncomeEligible)/1e18 ,
          "referralid" : referralid,
          "investmentAmount" : parseFloat(investmentAmount)/1e18 ,
          "earnedAmount" : parseFloat(earnedAmount)/1e18 ,
          "levelIncome" : parseFloat(levelIncome)/1e18,
          "globalPoolIncome" : parseFloat(globalPoolIncome)/1e18,
          "referralCount" : referralCount,
          "referralIncome" : parseFloat(referralIncome)/1e18,
          "teamVolume" : parseFloat(teamVolume)/1e18
        }
        let min_value = await getFormatMulticall(results, "minInvestment", 0);
        let max_value = await getFormatMulticall(results, "maxInvestment", 0);
        min_value = await bignumber(min_value[0])
        max_value = await bignumber(max_value[0])
        let min_with_value = await getFormatMulticall(results, "minWithdrawAmount", 0);
        min_with_value = await bignumber(min_with_value[0])
        var data = {
           provider : transfort,
           address : result[0],
           busdvalue  : busdbalance,
           "bonjourbalance" : bonjourbalance,
           userdetails : data_user,
           minvalue : parseFloat(min_value)/1e18,
           maxvalue : parseFloat(max_value)/1e18,
           minwithdrawvalue : parseFloat(investmentAmount) <= (40*1e18) ? 15 :  parseFloat(min_with_value)/1e18
        }
        console.log("infinal")
        return data
      }else{
        toastAlert('error', "Please select BSC chain network", 'network');
        localStorage.clear();
        // return {
        // }
      }
      
    }else{
      console.log("inelse")
      // return {

      // }
    }
  }catch(err){
    console.log(err,'mmmmmmmmmmmmmmmm')
    // return {
    // }
  }
}



export const getrecords_presale = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getrecords_presale`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getpresaledetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/presaledetails`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updateregister = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/join_now`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getlasttransaction = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlasttransaction`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_view_count = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/get_view_count`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updateuserrec = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updateuserrec`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const checkview = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkview`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updatereferral = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatereferral`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const checkreferal = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkreferal`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const addactivity = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/add_activity`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getuserrecords = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getuserrecords`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const gettotaluser = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/gettotaluser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const checkaccept = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkaccept`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const add_Address = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/addclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}
export const add_Address_new = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/addclaimlistuser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_nfclist = async (payload) => {
   try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getnfclist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const save_cartrequest = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/save_cartrequest`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const remove_Address = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/removeclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const claim_list = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}api/getclaimlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const eligible_claim_list = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}api/getclaimlist-new`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const filter_event = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/filter_event`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

function getFormatMulticall(results, name, pos) {
  try {
      var index = results && results.results && results.results.details && 
          results.results.details.callsReturnContext &&
          results.results.details.callsReturnContext.findIndex(val => val.reference == name);
      var returnVal = results.results.details.callsReturnContext[index] &&
          results.results.details.callsReturnContext[index].returnValues  ?
          results.results.details.callsReturnContext[index].returnValues : "";
      return returnVal;
  } catch (err) {
      console.log(err,"==+++++++")
      return "";
  }
}

async function bignumber(inputs){
  let ten =new BigNumber(inputs.hex,16);
  var value = ten.toString(10);
  return value;
}