let NODE_ENV = "production";

let Front_URL = "";
let Base_URL = "";
let rpcURL = "";
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = "";
let bscurl = "";
let adminAddress = "";
let bonjourcontract = "";
let busdcontract = "";
let icocontract = "";
let autopoolcontract = "";
let autopoolcontract2 = ""
let bonjourtokencontract = "";
let nftcartadminaddress = "";
let autopool2_rootuser = "";
let plancontract = "";
let bnbtousd = "";
let chainId = "";
let Server_URL = "";
let projectId = "";
const ownerAddr = "0x49E1a781BffF290350d0EBA905D42823F2308F39";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};



var lottery = "";
var lotterAdmin = "";
var lotteryToken = "";

var singleContract = "";
var WbnbtokenAddr = "";
var PankukuAddr = "";
var ContractAddress = "";




if (NODE_ENV == "production") {
  Front_URL = "https://www.1cosmos.io/";
  Server_URL = "https://api.webonjour.com/";
  networkVersion = 56;//56
  rpcURL = "https://bsc-dataseed.binance.org/";
  bscurl = "https://bscscan.com/";
  nftcartadminaddress = "0xEa6124A4fB9A15B82F1D2D68123Cd834890aCFFB";
  adminAddress = "0x9a427cbbac2c87cc58b953a049132708ab466013";
  bonjourcontract = "0x02AD27A0Cd5fF292ad81007dFEeBA364e896C73C";
  plancontract = "0xf80e81b04b5dfe3B293691d6377c5E38a0CF5143";
  bonjourtokencontract = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";
  icocontract = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b"
  busdcontract = "0x55d398326f99059fF775485246999027B3197955";
  autopoolcontract = "0x58af64BbF21067899252519568dE0c678518Ee01";
  autopoolcontract2 = "0xdc0032F907e882B26949dCaa56b7c45CE861De9e";//"0x52203FE48C69585e7F6AD1D3c845D19EC8f05539";
  autopool2_rootuser = "0x9A427CbbAC2C87Cc58B953a049132708AB466013";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 56;//56
  projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect

  lottery = "0x703c976CA913f94494c7594B48403d88f07040a5";
  lotterAdmin = "0x55D49B211348C86282d2DBe03C4cbfAea0BC6E2f";
  lotteryToken = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x703c976CA913f94494c7594B48403d88f07040a5";

} else if (NODE_ENV === "demo") {
  // Front_URL = "http://localhost:3000/";
  Front_URL = "https://bonjour-mlm-frontend-2022.pages.dev/";
  Server_URL = "https://demoapi.webonjour.com/";
  networkVersion = 56;//56
  rpcURL = "https://bsc-dataseed.binance.org/";
  bscurl = "https://bscscan.com/";
  nftcartadminaddress = "0xEa6124A4fB9A15B82F1D2D68123Cd834890aCFFB";
  adminAddress = "0x9a427cbbac2c87cc58b953a049132708ab466013";
  bonjourcontract = "0xEfF769AA404DF63a41015FdCCEa6c235DC0E46e0";
  plancontract = "0xf80e81b04b5dfe3B293691d6377c5E38a0CF5143";
  bonjourtokencontract = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";
  icocontract = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b"
  busdcontract = "0x55d398326f99059fF775485246999027B3197955";
  autopoolcontract = "0x58af64BbF21067899252519568dE0c678518Ee01";
  autopoolcontract2 = "0xdc0032F907e882B26949dCaa56b7c45CE861De9e";//"0x52203FE48C69585e7F6AD1D3c845D19EC8f05539";
  autopool2_rootuser = "0x9A427CbbAC2C87Cc58B953a049132708AB466013";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 56;//56
  projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect

  lottery = "0x703c976CA913f94494c7594B48403d88f07040a5";
  lotterAdmin = "0x55D49B211348C86282d2DBe03C4cbfAea0BC6E2f";
  lotteryToken = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x703c976CA913f94494c7594B48403d88f07040a5";





  //  networkVersion = 97;
  //  // rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  //  // bscurl = "https://testnet.bscscan.com/";
  //  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  //  bonjourcontract = "0xA699eA0f303Dc01089Eb12065E99e350dEf96dCA"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  //  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  //  icocontract        = "0x33f7E5aA19ddd51e2F7a903eE9EEfBb10bce08c0"
  //  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  //  chainId = 97;
  //  plancontract = "0x273D96a53F153c4Db9D4e7Ee3f5cDBc3C328A801";  
  //  projectId          = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
  // // bonjourcontract = "0xd4425C5e91a505cc751EB64334FbD2f203E69105"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  //  bonjourtokencontract = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
  //  autopoolcontract = "0xC5Fa18e30e8d8438351276478781297181B72563";
  //  autopoolcontract2 = "0xc57748aE1A0A85cF547451873591ceceC11FF2A4";
  //  autopool2_rootuser = "0x373F886904048975034488320E37d171E092C135";
  //  lottery = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
  //  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  //  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";
  //  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  //  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  //  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  //  ContractAddress = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:2053/";
  networkVersion = 97;
  rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  bonjourcontract = "0xc656FD21c07763f83b869ccbD57DB09779eCC017"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  bonjourtokencontract = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
  plancontract = "0xE13b6E36FBA2F8894a74beCA2A38B1d781428568";
  icocontract = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b"
  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  autopoolcontract = "0xC5Fa18e30e8d8438351276478781297181B72563";
  autopoolcontract2 = "0x84177eB840C9E0a2817C659463E0B39E354C6023";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 97;
  projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
  //
  lottery = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  rpcURL: rpcURL,
  networkVersion: networkVersion,
  toasterOption: toasterOption,
  adminAddress: adminAddress,
  bonjourcontract: bonjourcontract,
  plancontract: plancontract,
  busdcontract: busdcontract,
  autopoolcontract: autopoolcontract,
  autopoolcontract2: autopoolcontract2,
  autopool2_rootuser: autopool2_rootuser,
  nftcartadminaddress: nftcartadminaddress,
  bonjourtokencontract: bonjourtokencontract,
  icocontract: icocontract,
  bnbtousd: bnbtousd,
  bscurl: bscurl,
  chainId: chainId,
  ownerAddr: ownerAddr,
  lotteryToken: lotteryToken,
  lottery: lottery,
  lotterAdmin: lotterAdmin,
  ContractAddress: ContractAddress,
  PankukuAddr: PankukuAddr,
  singleContract: singleContract,
  WbnbtokenAddr: WbnbtokenAddr,
  projectId: projectId
};

export default key;
