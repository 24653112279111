import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import JoinNow from './pages/join-now';
import Settings from './pages/settings';
import Autopool from "./pages/autopool";
import NFCCard from "./pages/nfc-card";
import DashboardNew from './pages/dashboard-new';
import Terms from "./pages/terms";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc,bscTestnet} from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { Provider } from "react-redux";
import store from './store'
import config from "./config/config";

import MiniBonjour from "./pages/one$working-plan";
import JoinNowplan from "./pages/join-nowplan";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [infuraProvider({
    apiKey: config.projectId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version:2
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "join-now", element: <JoinNow /> },
    { path: "join-now/:id", element: <Home /> },
    { path: "bksdiubsacewgn", element: <Settings /> },
    //{ path: "bonjourpool", element: <Autopool /> },
    //{ path: "bonjourpool/:id", element: <Autopool /> },
    { path: "nfc-card", element: <NFCCard /> },
    { path: "bonjourpool2", element: <DashboardNew /> },
    { path: "bonjourpool2/:id", element: <DashboardNew /> },
    { path: "terms", element: <Terms /> },
    { path: "MiniBonjour", element: <MiniBonjour /> },
    { path: "joinnowplan", element: <JoinNowplan /> },
    { path: "joinnowplan/:id", element: <JoinNowplan /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <ToastContainer />
        <WagmiConfig config={wconfigInitial}>
        <App />
        </WagmiConfig>
      </Router>
    </Provider>
  );
};

export default AppWrapper;