import React, { useEffect, useState } from "react"; 
import Footer from "../components/Footer.js";  
import Navbar from "../components/Navbar.js";
import config from "../config/config";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import planabi from "../ABI/PlanABI";
import BigNumber from "bignumber.js";
import { type WalletClient, useWalletClient } from 'wagmi'
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Onedollarplan() {

  const [userdetails, setuserdetails] = useState({});
  const [data, setdata] = useState({});
  const [totalusercount, settotalusercount] = useState(0);
  const [referralcount, setreferralcount] = useState(0);
  const [roiincome, setroiincome] = useState(0);
  const [totalgain,settotalgain] = useState(0);
  const [claimprogress,setclaimprogress] = useState("false");
  const [spotreferralcount,setspotreferralcount] = useState(0);
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })
  const [ownerwallet,setownerwallet] = useState("");

  useEffect(() => {
    getdetails();
  }, [walletClient]);

  async function getdetails() {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        setdata(datas);
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        var planContract = new web3.eth.Contract(planabi, config.plancontract);
        let user_details = await planContract.methods.users(datas.address).call();
        let ownerwall = await planContract.methods.ownerWallet().call();
        setownerwallet(ownerwall);
        if (user_details && user_details.isExist) {
          setuserdetails(user_details);
          getuserinfo(datas);
        } else {
          window.location.href = "/joinnowplan";
        }
      } else {
        //window.location.href = "/joinnowplan";
      }
  }

  async function getuserinfo(datas){
    try{
      let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
      var planContract = new web3.eth.Contract(planabi, config.plancontract);
      const multicall = new Multicall({
        web3Instance: web3,
      });
      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.plancontract,
          abi: planabi,
          calls: [
            {
              reference: "roiEarningAmount",
              methodName: "roiEarningAmount",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "referCount",
              methodName: "referCount",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "totalUsers",
              methodName: "totalUsers",
              methodParameters: [],
            },
            {
              reference: "viewUserReferral",
              methodName: "viewUserReferral",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "viewUserReferralCount",
              methodName: "viewUserReferralCount",
              methodParameters: [localStorage.getItem("account")],
            },

          ],
        },
      ];
       const results = await multicall.call(contractCallContext);
      var roiEarningAmount = await getFormatMulticall(results, "roiEarningAmount", 0);
      var referCount = await getFormatMulticall(results, "referCount", 0);
      var totalUsers = await getFormatMulticall(results, "totalUsers", 0);
      var viewUserReferral = await getFormatMulticall(results, "viewUserReferral", 0);
      var viewUserReferralCount = await getFormatMulticall(results, "viewUserReferralCount", 0);
      let viewUserReferral_Count = await bignumber(viewUserReferralCount[0]);
      setspotreferralcount(viewUserReferral_Count);
      let total_user = await bignumber(totalUsers[0]);
      let refer_Count = await bignumber(referCount[0]);
      let roi_Earning_Amount = await bignumber(roiEarningAmount[0]);
      settotalusercount(total_user);
      setreferralcount(refer_Count);
      setroiincome(roi_Earning_Amount);
      let totalern = await planContract.methods.totalEarnedAmount(localStorage.getItem("account")).call();
      settotalgain(totalern);
    }catch(err){
      console.log(err,"=====error")
    }
  }

  async function Claimamt() {
    setclaimprogress("true");
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        var planContract = new web3.eth.Contract(planabi, config.plancontract);
        let user_details = await planContract.methods.users(datas.address).call();
        if (user_details && user_details.isExist) {
          if (parseFloat(user_details.earnedAmount) >= 5000000000000000000) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            let register = await planContract.methods
              .claimRewards()
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              toastAlert(
                "success",
                "Claimed Successfully",
                "network"
              );
              setclaimprogress("false");
              window.location.reload();
            } else {
              setclaimprogress("false");
              toastAlert("error", "Failed , try again later", "network");
            }
          } else {
            setclaimprogress("false");
            toastAlert("error", "Insufficient Earnings", "network");
          }
        } else {
          setclaimprogress("false");
          toastAlert("error", "Join First to proceed Further", "network");
          window.location.href = "/joinnowplan";
        }
      } else {
        setclaimprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      setclaimprogress("false");
    }
  }

 
  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText =
      config.Front_URL + "joinnowplan/" + localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  async function empty(){

  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="wholebg">
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="onedollarplan" >
              <div className="h_flx">
                <h5>Dashboard</h5>
                <div className="flx">
                 <span> Affiliate link :</span>
                 <div>
                 <a href="#" >{
                              config.Front_URL +
                              "joinnowplan/" +
                              localStorage.getItem("account")
                            }</a>
                 <img src={require("../assets/images/onedollarplan/copy.png")} className="img-fluid copy" alt="Icon" onClick={copyToClipboard}/>
                 </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-3  col-lg-4 ">
                 <div className="left_boxes">
                   <div className="box">
                    <div className="img_r">
                    <img src={require("../assets/images/onedollarplan/l1.png")} className="img-fluid" alt="Icon"/>
                    </div>
                    <h5>{spotreferralcount ? spotreferralcount : 0 }</h5>
                    <p>Spot Referral Count</p>
                   </div>
                   <div className="box">
                   <div className="img_r">
                    <img src={require("../assets/images/onedollarplan/l2.png")} className="img-fluid copy" alt="Icon"/>
                    </div>
                    <h5>{userdetails && userdetails.referralEarn ? (parseFloat(userdetails.referralEarn)/1e18).toFixed(4) : 0 }</h5>
                    <p>Spot referral income</p>
                   </div>
                   <div className="box">
                   <div className="img_r">
                    <img src={require("../assets/images/onedollarplan/l3.png")} className="img-fluid copy" alt="Icon"/>
                    </div>
                    <h5>{userdetails && userdetails.levelEarn ? (parseFloat(userdetails.levelEarn)/1e18).toFixed(4) : 0 }</h5>
                    <p>Level income</p>
                   </div>
                 </div>
                </div>
                <div className="col-xxl-9  col-lg-8 ">
                  <div className="img_box">
                    <img src={require("../assets/images/onedollarplan/one1.png")} className="img-fluid" alt="Icon"/>
                  </div>
                  <div className="claim_box">
                    <div className="box">
                      <div>
                      <h5>{totalgain ? (parseFloat(totalgain)/1e18).toFixed(4) : 0 }</h5>
                      <p>Total Gained</p>
                      </div>
                    </div>
                    <div className="box">
                      <div>
                      <h5>{userdetails && userdetails.earnedAmount && parseFloat(userdetails.earnedAmount)/1e18>5 ? "5" : userdetails && userdetails.earnedAmount ? (parseFloat(userdetails.earnedAmount)/1e18).toFixed(4) : 0 }</h5>
                      <p>Claim Amount</p>
                      </div>
                    </div>
                    <button className="primary_btn" onClick={claimprogress=="false"?()=>Claimamt():()=>empty()}>{claimprogress == "false" ? "Claim NOW" : "Processing..."}</button>
                  </div>
                  <div className="blw_box">
                  <div className="row">
                      <div className="col-lg-6">
                        <div className="box">
                          <div className="img_r">
                            <img src={require("../assets/images/onedollarplan/b1.png")} className="img-fluid" alt="Icon"/>
                          </div>
                          <h5>{totalusercount}</h5>
                          <p>Total Participant</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="box">
                          <div className="img_r">
                            <img src={require("../assets/images/onedollarplan/b2.png")} className="img-fluid" alt="Icon"/>
                          </div>
                          <h5>{ownerwallet == localStorage.getItem("account") ? totalusercount :  referralcount}</h5>
                          <p>Team Count</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="box">
                          <div className="img_r">
                            <img src={require("../assets/images/onedollarplan/b3.png")} className="img-fluid" alt="Icon"/>
                          </div>
                          <h5>{roiincome ? (parseFloat(roiincome)/1e18).toFixed(4) : 0 }</h5>
                          <p>Reward Amount</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="box">
                          <div className="img_r">
                            <img src={require("../assets/images/onedollarplan/b4.png")} className="img-fluid" alt="Icon"/>
                          </div>
                          <h5>{userdetails && userdetails.reInvestCount ? userdetails.reInvestCount : 0 }</h5>
                          <p>Reinvest count</p>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
}
