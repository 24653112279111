import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar_inner from "../components/Navbar_inner.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import { getCurAddr, updateregister, checkreferal } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import bonjourapi from "../ABI/bonjourABI";
import bonjourtokenabi from "../ABI/bonjourtokenABI";
import busdabi from "../ABI/busdABI";
import config from '../config/config';
import { type WalletClient, useWalletClient } from 'wagmi'

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function JoinNow(props) {

  //var { id } = useParams();
  const [data, setdata] = useState({});
  const [referaladdress, setreferaladdress] = useState("");
  const [progress, setprogress] = useState("false");
  const [investamt, setinvestamt] = useState(0);
  const [hadalready, sethadalready] = useState("no");
  const [isTokenjoin, setisTokenjoin] = useState(false);

  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    getdetails();
  }, [walletClient]);

  const referralchange = (e) => {
    setreferaladdress(e.target.value);
  }

  const investamtchange = (e) => {
    setinvestamt(e.target.value);
  }

  //async function checkreferal(){
  // if(id && id!=null && id!=undefined && id!=""){
  //   setreferaladdress(id);
  // }
  //}

  async function getdetails() {
    let datas = await getCurAddr();
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      setdata(datas);
      if (datas.userdetails &&
        datas.userdetails.isExist) {
        window.location.href = "/dashboard"
      } else {
        getreferral(datas)
      }
    }
  }

  async function getreferral(_data) {
    let inputdata = {
      "useraddress": _data.address,
    }
    let rest = await checkreferal(inputdata);
    if (rest && rest.data && rest.data.data) {
      setreferaladdress(rest.data.data.parent)
      sethadalready("yes")
    } else {
      sethadalready("no")
    }
  }

  async function empty() {

  }

  async function joinnow() {
    console.log(isTokenjoin, "=====isisisisisiis")
    try {
      setprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" && localStorage.getItem("account")) {
        if (datas.userdetails &&
          datas.userdetails.isExist) {
          setprogress("false")
          toastAlert('error', "Already Joined", 'network');
          window.location.href = "/dashboard";
        } else {
          if (referaladdress.toLowerCase() != (datas.address).toLowerCase()) {
            let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
            if (web3.utils.isAddress(referaladdress)) {
              if (investamt && parseFloat(investamt) > 0) {
                if (parseFloat(investamt) >= parseFloat(datas.minvalue).toFixed(2) && parseFloat(investamt) <= parseFloat(datas.maxvalue).toFixed(2)) {
                  var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
                  var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourcontract);
                  var BonjourTokenContract = new web3.eth.Contract(bonjourtokenabi, config.bonjourtokencontract);
                  var tokenbalance = await BonjourTokenContract.methods.balanceOf(datas.address).call();
                  let userdetails = await bonjourContract.methods.users(referaladdress).call();
                  if (userdetails.isExist) {
                    let newinvestamt = (parseFloat(investamt) * 10) / 100;
                    let reducedamt = parseFloat(investamt) - parseFloat(newinvestamt);
                    let updateinvesttoken = (parseFloat(newinvestamt) * (0.005)) * 1e18;
                    let invest_amt = isTokenjoin ? (parseFloat(reducedamt) * 1e18) : (parseFloat(investamt) * 1e18);
                    invest_amt = await convert(invest_amt);
                    let actualamt = (parseFloat(investamt) * 1e18);
                    let sendamt = await convert(actualamt);
                    let allowedamt = await convert(10000000000000000000000000)
                    if (parseFloat(datas.busdvalue) >= parseFloat(invest_amt)) {
                      if ((isTokenjoin && parseFloat(tokenbalance) >= parseFloat(updateinvesttoken)) || (!isTokenjoin)) {
                        var allowance = await busdContract.methods.approve(config.bonjourcontract, allowedamt.toString()).send({
                          from: datas.address
                        });
                        if (allowance) {
                          let Bonjourtokentransfer = isTokenjoin ? await BonjourTokenContract.methods.approve(config.bonjourcontract, updateinvesttoken.toString()).send({
                            from: datas.address
                          }) : true;
                          if (Bonjourtokentransfer) {
                            let register = await bonjourContract.methods.regUser(referaladdress, sendamt.toString(), isTokenjoin).send({
                              from: datas.address
                            })
                            if (register && register.status && register.transactionHash) {
                              let loguser = await bonjourContract.methods.users(datas.address).call();
                              let reg_data = {
                                address: datas.address,
                                referraladdress: referaladdress,
                                userid: loguser.id,
                                amount: investamt,
                                event: "joined",
                                hash: register.transactionHash
                              }
                              await updateregister(reg_data);
                              setprogress("false")
                              toastAlert('success', "Joined Successfully", 'network');
                              window.location.href = "/dashboard"
                            } else {
                              setprogress("false")
                              toastAlert('success', "Failed , try again later", 'network');
                            }
                          } else {
                            setprogress("false")
                            toastAlert('success', "Failed , try again later", 'network');
                          }
                        } else {
                          setprogress("false")
                          toastAlert('success', "Failed , try again later", 'network');
                        }
                      } else {
                        setprogress("false")
                        toastAlert('error', "Low Bonjour Token Balance", 'network');
                      }
                    } else {
                      setprogress("false")
                      toastAlert('error', "Low USDT Balance", 'network');
                    }
                  } else {
                    setprogress("false")
                    toastAlert('error', "Referral user not exist", 'network');
                  }
                } else {
                  setprogress("false")
                  let alert = "Invest Amount must be greater than or equal to Minimun invest Amount : " + parseFloat(datas.minvalue).toFixed(2) + " and less than or equal to Maximum invest Amount : " + parseFloat(datas.maxvalue).toFixed(2);
                  toastAlert('error', alert, 'network');
                }
              } else {
                setprogress("false")
                toastAlert('error', "Enter Valid Invest Amount", 'network');
              }
            } else {
              if (referaladdress && referaladdress != null && referaladdress != undefined && referaladdress != "") {
                setprogress("false")
                toastAlert('error', "Invalid Referral Link", 'network');
              } else {
                setprogress("false")
                toastAlert('error', "Please Enter valid Address", 'network');
              }
            }
          } else {
            setprogress("false")
            toastAlert('error', "Your Wallet and Referral both are same", 'network');
          }
        }
      } else {
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      console.log(err, "=-===allowanceallowanceallowance")
      setprogress("false")
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }

  // function myFunction() {
  //   var x = document.getElementById("myCheck").defaultChecked; 
  // }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar_inner />
      <div className="inner_wrapper joinNowBg">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Join Now</h2>
                  <div className="primary_box dash_box mt-4">
                    <div className="form-group row">
                      <div className="col-lg-6 m-auto text-start">
                        <label for="exampleFormControlInput1" class="form-label">Referral ID</label>
                        <input type="text" class="form-control primary_inp mb-3" id="exampleFormControlInput1" placeholder="Enter referral ID" onChange={referralchange} disabled={hadalready && hadalready != undefined && hadalready != null && hadalready != "no" ? "disabled" : ""} value={referaladdress} />
                        <label for="exampleFormControlInput1" class="form-label">Invest Amount (USDT)</label>
                        <input type="Number" class="form-control primary_inp mb-3" id="exampleFormControlInput1" placeholder="Enter Invest Amount" value={investamt} onChange={investamtchange} min={data.minvalue} max={data.maxvalue} />

                        <div class="form-check form-switch toggle">
                          <input class="form-check-input" type="checkbox" role="switch" id="myCheck" onClick={() => setisTokenjoin(!isTokenjoin)} checked={isTokenjoin} />
                          <label class="form-check-label" for="myCheck">with BNJR Token</label>
                        </div>
                        <span>{isTokenjoin && parseFloat(investamt) > 0 && " ( " + (parseFloat(investamt) - ((parseFloat(investamt) * 10) / 100)) + " USDT & " + (((parseFloat(investamt) * 0.005) * 10) / 100) + " BNJR you will pay )"}</span>

                        <button className="primary_btn" type="button" onClick={progress == "false" ? () => joinnow() : () => empty()}>{progress == "false" ? "Submit" : "Processing..Please Wait.."}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
